import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>
      It's gone{' '}
      <span role="img" aria-label="Crying face">
        😢
      </span>
    </h1>

    <p>Very sorry, but we're having trouble finding that page...</p>
    <p>
      Never fear, a team of well-trained ninjas has been dispatched to locate it
      again.
    </p>
    <p>
      In the meantime, you can make a cup of tea, or return to the{' '}
      <Link to="/">home page</Link>.
    </p>
  </Layout>
);

export default NotFoundPage;
